import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Profile and Curriculum Vitae`}</h2>
    <h3>{`Name`}</h3>
    <p>{`Awaya Takuma`}</p>
    <h3>{`Jobs`}</h3>
    <ul>
      <li parentName="ul">{`2019- 株式会社リクルートテクノロジーズ`}</li>
      <li parentName="ul">{`2018-2019 消費者庁(Assistant Researcher)`}</li>
    </ul>
    <h3>{`Education`}</h3>
    <ul>
      <li parentName="ul">{`2017-2019 MA in 大阪大学 経済学研究科 経済学専攻`}</li>
      <li parentName="ul">{`2013-2017 BA in 大阪大学 経済学部 経済・経営学科`}</li>
    </ul>
    <h3>{`人生サマリ`}</h3>
    <p>{`漠然と勉強を続けていたいという思いから研究者になることを志すが、研究と勉強が別物であること、カネなし・コネなし・実力なしな現実に直面し、ビジネス界に転生することを決意。`}</p>
    <p>{`仮にも研究者を志した者の矜持として修士を通して博士進学しても遜色ないように過ごそうという執念に囚われた結果就職活動をおろそかにして痛い目にあう。同期の過半以上が 3 月の時点で内定もらってたからね。3 月解禁なんて大嘘ですね。`}</p>
    <p>{`経済学のビジネス応用に興味があって、その実現には IT が不可欠であろうと思ってとりあえず計算機科学や Web 技術を全方面勉強しています。情報系の勉強が楽しすぎて当初の経済学のビジネスへの応用というモチベーションが下がり気味ですが今も僕はきっと勉強をしています。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      