import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`このサイトについて`}</h2>
    <p>{`なんか画面上に雪を降らせたいなーという欲を以前から持っていて、そんな感じでポートフォリオサイト作るなら「夜中の降雪」をモチーフにしたいなと思っていました。最初は簡単な JS のライブラリでも使ってレトロな画面にしようと思っていたんですが、hpp さんのポートフォリオ及び React-Three-js の Qiita 記事を閲覧させていただき、Web 画面描画の可能性の底知れなさを痛感しました。ここまで JS でできるのであれば、変にチープな方向ではなく、自分の感性に合うようなものにしようと決めました。`}</p>
    <p>{`hpp さんのポートフォリオ及びレポジトリをがっつり参考にさせてもらったのは技術スタックやディレクトリ構成と見てもらえば伝わるかなと(笑)`}</p>
    <h2>{`背景について`}</h2>
    <p>{`最初は雪に見立てた粒子がキラキラと乱反射して落ちてくるだけで楽しくていろいろパラメータ調整して遊んでいました。左右にゆらゆら動かしてみたり、粒子の速度に三角関数を使ってみたり、遠近を調節してみたり、色合いを変化させたり色の数を増やしたり減らしたり...。 しまいには粒子の運動が夏の星に見えてきたりしてモチーフを変更しようかとかいろいろ考えながら作る作業はとても楽しかったです。結局今の形に落ち着きました。`}</p>
    <h2>{`感想`}</h2>
    <p>{`背景はともかくアプリケーションのディレクトリ構成や各コンポーネントの責務などは完全に手探りで進めたせいで自分でも目が当てられないほどカオスな状況に陥りました。しばらくコードは見たくないですね...笑。あとモーダルやその他コンポーネントを作るにあたって自分がいかに世の中の Web サイトの UI に関して意識を向けていなかったのかを痛感できました。もっと他人のレポジトリをみたり、Web サイトを分析したりして精進していきたいと思います。`}</p>
    <h2>{`参考にさせていただいたサイト`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`hpp さんのポートフォリオサイト (`}<a href="https://hpprc.com/"><a parentName="p" {...{
              "href": "https://hpprc.com/"
            }}>{`https://hpprc.com/`}</a></a>{`)`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`hpp さんのポートフォリオサイトのレポジトリ (`}<a href="https://github.com/hppRC/hppPortfolio"><a parentName="p" {...{
              "href": "https://github.com/hppRC/hppPortfolio"
            }}>{`https://github.com/hppRC/hppPortfolio`}</a></a>{`)`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`hpp さんの Threejs (react-three-fiber) の入門記事 (`}<a href="https://qiita.com/hppRC/items/b3e292e210d02005120f"><a parentName="p" {...{
              "href": "https://qiita.com/hppRC/items/b3e292e210d02005120f"
            }}>{`https://qiita.com/hppRC/items/b3e292e210d02005120f`}</a></a>{`)`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Three.js 一般に関するドキュメント (`}<a href="https://qiita.com/hppRC/items/b3e292e210d02005120f"><a parentName="p" {...{
              "href": "https://qiita.com/hppRC/items/b3e292e210d02005120f"
            }}>{`https://qiita.com/hppRC/items/b3e292e210d02005120f`}</a></a>{`)`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`tim-soft 氏の降雪に関するレポジトリ (`}<a href="https://github.com/tim-soft/react-particles-webgl"><a parentName="p" {...{
              "href": "https://github.com/tim-soft/react-particles-webgl"
            }}>{`https://github.com/tim-soft/react-particles-webgl`}</a></a>{`)`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`配色の参考 (`}<a href="https://nipponcolors.com/"><a parentName="p" {...{
              "href": "https://nipponcolors.com/"
            }}>{`https://nipponcolors.com/`}</a></a>{`)`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`その他公式ドキュメント諸々`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      