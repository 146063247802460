import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`メールアドレス`}</h2>
    <ul>
      <li parentName="ul">{`tttt4aaaa`}{`[at]`}{`gmail.com`}</li>
    </ul>
    <h2>{`twitter`}</h2>
    <ul>
      <li parentName="ul"><a href="https://twitter.com/acasune">https://twitter.com/acasune</a></li>
    </ul>
    <h2>{`Github`}</h2>
    <ul>
      <li parentName="ul"><a href="https://github.com/Acasune">https://github.com/Acasune</a></li>
    </ul>
    <h2>{`ブログ`}</h2>
    <ul>
      <li parentName="ul">
        <a href="http://acasu.net/">アカスネの技術ブログ</a>
      </li>
      <li parentName="ul">
        <p parentName="li">{`雑記ブログ(そのうち作ります。きっと。)`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      