import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Likes`}</h2>
    <p>{`そのうち加筆します。`}</p>
    <h2>{`小説`}</h2>
    <h2>{`映画`}</h2>
    <h2>{`音楽`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      