import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`プログラミング言語`}</h2>
    <h3>{`High (なんらかの作品や論文を生み出した経験がある。)`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Python`}<br parentName="p"></br>{`
`}{`論文執筆時にデータクレンジングやデータ分析、データの可視化等に用いました。勤怠入力の自動化など捨てスクリプトを書く際にまず候補に入れる言語です。`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Java`}<br parentName="p"></br>{`
`}{`業務で使っています。一通り書け、レビューをしています。Effective Java 信者です。`}</p>
      </li>
    </ul>
    <h3>{`Middle (たまに使うけどそこまで詳しくはない。)`}</h3>
    <ul>
      <li parentName="ul">{`JavaScript and Typescript`}<br parentName="li"></br>
        {`人並み。TypeScript 書いてて楽しかったのでもうちょっと勉強したい。`}</li>
      <li parentName="ul">{`C/C++`}<br parentName="li"></br>
        {`C はデータ構造とアルゴリズムの勉強に使っています。中〜大規模の開発はともかく、小規模(ひとつのデータ構造の実装&それのテスト)程度なら使えて書いてて実は楽しかったりする。`}<br parentName="li"></br>
        {`C++は競技プログラミングのコントローラとして使用。AtCoder の highest が 810(緑)くらい。ぶっちゃけ競技プログラミングでしか使ったことないので手続き的な書き方しかできません><。`}</li>
    </ul>
    <h3>{`Low (Hello World に毛が生えた程度。)`}</h3>
    <ul>
      <li parentName="ul">{`Haskell`}<br parentName="li"></br>
        {`クールですよね、ハスケラーって。データ構造とアルゴリズムの Haskell 実装をそのうちする予定。`}</li>
      <li parentName="ul">{`R`}<br parentName="li"></br>
        {`学部の TA レベルならできます。学生時代には Python をメインに使っていたのでそんなに詳しくはないです。`}</li>
      <li parentName="ul">{`Stata`}<br parentName="li"></br>
        {`論文書くときの分析で使いました。ぽちぽちで使えるのでいいですよね。`}</li>
    </ul>
    <h2>{`その他`}</h2>
    <h3>{`英語`}</h3>
    <ul>
      <li parentName="ul">{`R → 各種公式ドキュメントは苦もなく読める。英語の教科書は割と気合と期間が必要。`}</li>
      <li parentName="ul">{`L → ネイティブの講演等はたまーにリエゾンの聞き取りとかがきついけど大意を誤解せずに聞き取りできる程度。`}</li>
      <li parentName="ul">{`W → まあまあ書ける純ジャパってレベルだと思う。`}</li>
      <li parentName="ul">{`S → ネイティブにそこそこ気を使ってもらえばまあまあ意思疎通はできるレベルと思う。`}</li>
    </ul>
    <h3>{`経済学`}</h3>
    <ul>
      <li parentName="ul">{`そこそこよい成績で修士卒業したヨ!!
マクロはほぼ忘れたけどミクロとエコノメの勘は割とすぐに取り戻せると思う。`}</li>
    </ul>
    <h2>{`資格`}</h2>
    <ul>
      <li parentName="ul">{`TOEFL-ibt:82 (2016/06)`}</li>
      <li parentName="ul">{`TOEIC:905 (2017/01)`}</li>
      <li parentName="ul">{`Oracle Certified Java Programmer, Silver SE 8 (2019/09)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      